define("discourse/plugins/discourse-interstitials/discourse/initializers/discourse-interstitials", ["exports", "@ember/runloop", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/cookie", "discourse/lib/plugin-api", "discourse-common/lib/get-owner", "discourse/plugins/discourse-interstitials/discourse/components/modal/interstitial-alert"], function (_exports, _runloop, _ajax, _ajaxError, _cookie, _pluginApi, _getOwner, _interstitialAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const basePath = "/discourse-interstitial/my_interstitials";
  function getAvailableInterstitial(interstitials, filters) {
    let interstitial = null;
    for (let i = 0; i < interstitials.length; i++) {
      let snoozeTime = (0, _cookie.default)(`snoozed_interstitial_${interstitials[i].id}`);
      if (snoozeTime && moment().isBefore(snoozeTime)) {
        continue;
      }
      if (filters && filters(interstitials[i])) {
        continue;
      }
      interstitial = interstitials[i];
    }
    return interstitial;
  }
  function showInterstitial(siteSettings, interstitial, shownInterstitials) {
    if (shownInterstitials.includes(interstitial)) {
      return;
    }
    shownInterstitials.push(interstitial);
    if (interstitial.anonymous_users && siteSettings.login_required) {
      return;
    }
    (0, _getOwner.getOwnerWithFallback)().lookup("service:modal").show(_interstitialAlert.default, {
      model: {
        basePath,
        ...interstitial
      }
    });
  }
  function handleExitIntent(siteSettings, interstitials, shownInterstitials) {
    document.addEventListener("mouseout", e => {
      if (!e.toElement && !e.relatedTarget) {
        let interstitial = getAvailableInterstitial(interstitials, i => {
          return !i.exit_intent_trigger;
        });
        if (!interstitial) {
          return;
        }
        showInterstitial(siteSettings, interstitial, shownInterstitials);
      }
    });
  }
  function randomABVariable() {
    return Math.round(Math.random());
  }
  function startTimer(context, siteSettings, interstitial, shownInterstitials) {
    let interval = interstitial.time_spent_trigger || 1000;
    return (0, _runloop.later)(context, () => {
      showInterstitial(siteSettings, interstitial, shownInterstitials);
    }, interval);
  }
  function registerImpression(interstitial) {
    (0, _ajax.ajax)(`/discourse-interstitial/impressions`, {
      type: "POST",
      data: {
        impression: {
          discourse_interstitial_interstitial_id: interstitial.id,
          ab_visible: false
        }
      }
    }).catch(e => this.set("error", (0, _ajaxError.extractError)(e)));
  }
  function initializeDiscourseInterstitial(api, siteSettings) {
    let shownInterstitials = [];
    (0, _ajax.ajax)(`${basePath}.json`).then(response => {
      let interstitial = getAvailableInterstitial(response.my_interstitials);
      if (!interstitial) {
        return;
      }
      let abTestingCookie = `ab_testing_interstitial_${interstitial.id}`;
      if (siteSettings.enable_ab_testing && (0, _cookie.default)(abTestingCookie) !== "1") {
        if ((0, _cookie.default)(abTestingCookie) === "0") {
          return;
        }
        if (randomABVariable() === 0) {
          (0, _cookie.default)(abTestingCookie, 0);
          if (siteSettings.enable_interstitial_analytics) {
            registerImpression(interstitial);
          }
          return;
        }
        (0, _cookie.default)(abTestingCookie, 1);
      } else if (!siteSettings.enable_ab_testing && siteSettings.enable_interstitial_analytics) {
        registerImpression(interstitial);
      }
      if (siteSettings.enable_exit_intent_trigger) {
        handleExitIntent(siteSettings, response.my_interstitials, shownInterstitials);
      }
      let timer = startTimer(this, siteSettings, interstitial, shownInterstitials);
      document.body.addEventListener("keydown", function () {
        (0, _runloop.cancel)(timer);
        timer = startTimer(this, siteSettings, interstitial, shownInterstitials);
      });
      api.onAppEvent("composer:closed", function () {
        timer = startTimer(this, siteSettings, interstitial, shownInterstitials);
      });
      api.onAppEvent("composer:will-open", function () {
        (0, _runloop.cancel)(timer);
      });
    });
  }
  var _default = _exports.default = {
    name: "discourse-interstitial",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.discourse_interstitial_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.24", api => initializeDiscourseInterstitial(api, siteSettings));
      }
    }
  };
});